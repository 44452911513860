import React from 'react';
import { NavLink } from "react-router-dom";

function NavBar() {
  return(
    <nav className="nav">
      <NavLink exact activeClassName="active" to="/" className='navbar-items link'><span>home.</span></NavLink>
      <a href="https://www.postable.com/phanwheeler" className='navbar-items link'><span>snail mail.</span></a>
      <NavLink exact activeClassName="active" to="/contact" className='navbar-items link'><span>contact us.</span></NavLink>
    </nav>
  )
}

export default NavBar
