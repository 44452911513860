import './App.css';
import React from 'react';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './elements/Home';
import Contact from './elements/Contact';
import Footer from './elements/Footer';

class App extends React.Component {

  render() {
    // const heroku = process.env.REACT_APP_DATABASEURL
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/contact' element={<Contact />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    );
  }

}

export default App;
